import { render, staticRenderFns } from "./Bridge.vue?vue&type=template&id=e5d90116&scoped=true&"
import script from "./Bridge.vue?vue&type=script&lang=ts&"
export * from "./Bridge.vue?vue&type=script&lang=ts&"
import style0 from "./Bridge.vue?vue&type=style&index=0&id=e5d90116&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5d90116",
  null
  
)

export default component.exports